/*
 * 404 Styles
 */

.content .fourOhFour {
  width: 100%;
  height: 100%;
  background-color: #F2F3F4;
  background-image: url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23d4d4d4' fill-opacity='0.32'%3E%3Cpath d='M50 50c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10zM10 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10c0 5.523-4.477 10-10 10S0 25.523 0 20s4.477-10 10-10zm10 8c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm40 40c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  padding-top: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content .fourOhFour .messageWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.15);
  margin: 0 1.25rem 1.25rem;
  padding: 1rem 1rem 1.5rem;
}

.content .fourOhFour .messageWrap h1 {
}

.content .fourOhFour .messageWrap p {
  margin: 0 0 1rem 0;
  font-size: 1.1rem;
}

.content .fourOhFour .messageWrap p.sub {
  font-size: 1rem;
}

.content .fourOhFour .messageWrap a {
  color: #004bca;
}

.content .fourOhFour .messageWrap img {
  margin-bottom: 1.5rem;
}
