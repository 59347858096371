/*
 * General styles
 */

html {
  font-size: 16px;
  background-color: #4283f3;
}

body {
  background-color: #fff;
}

.hidden {
  display: none !important;
}

* {
  box-sizing: border-box;
}

body, .root, .App {
  font-family: 'Alata', sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Alatsi', sans-serif;
}


/*
 * Input styles
 */

input.email:hover {
  border-color: #c1c1c1;
}

.privacyWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2rem;
  cursor: pointer;
}

.privacyWrap .privacy {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.privacyWrap .privacy:focus + .privacyCheckbox {
  border-color: rgb(61, 127, 238);
}

.privacyWrap .privacyCheckbox {
  position: relative;
  top: 0;
  left: 0;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  background-color: #fbfbfb;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #dfdfdf;
  transition: 0.15s ease;
  box-shadow: 0 1px 0 rgba(0,0,0,0.2);
}

.privacyWrap:hover .privacy + .privacyCheckbox {
  background-color: #f7f7f7;
  background-color: #fbfbfb;
  border-color: #c1c1c1;
}

.privacyWrap .privacy:checked + .privacyCheckbox {
  background-color: #2196F3;
  background-color: #3D7FEE;
  box-shadow: 0 0 0 rgba(0,0,0,0.2);
}

.privacyWrap:hover .privacy:checked + .privacyCheckbox {
  background-color: #5A95F7;
  background-color: #1F66DA;
  border-color: #1F66DA;
  border-color: #dfdfdf;
}

.privacyWrap .privacyCheckbox:after {
  content: '';
  position: absolute;
  display: block;
  top: 4px;
  left: 9px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.privacyWrap:hover .privacyCheckbox:after {
  border-color: #dedede;
}

.privacyWrap .privacy:checked + .privacyCheckbox:after {
  display: block;
  border-color: #fff;
}

.privacyWrap .privacyLabel {
  padding: 0 0 0 0.5rem !important;
  font-size: 0.95rem;
  color: #1d1d1d;
}

.privacyWrap .privacyLabel a {
  color: #004bca;
}


/*
 * Content styles
 */

.content {
  margin-top: 4.5rem;
  background-color: #fff;
}

.content.full {
  height: 100%;
}
